import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Link} from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import lime from '@material-ui/core/colors/lime';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import jeshurun from '../images/jeshurun1.jpg';
import ThreejsHome from './ThreejsHome';
import Hidden from '@material-ui/core/Hidden';
const useStyles = makeStyles(theme => ({
  
  foundationItemGrid: {
    
    paddingTop: '2rem',
    diplay: 'flex',
    //flexDirection: 'row-reverse',
    marginTop: '2.5rem',
   // background: '#ffffde',
    background: '#ffffce',
    backgroundColor: '#7f5a83',
    backgroundImage: 'linear-gradient(317deg, #7f5a83 0%, #0d324d 74%)',
    marginLeft: 'auto',
    marginRight: 'auto',
    //height: '100vh',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]:{
      flexDirection: 'row',
      paddingTop: 50,
      marginTop: 0,
    }
  
  },
  media:{
    
    objectFit: 'cover',
    width: '50%',
    
    
    minHeight: '620px',
    
    [theme.breakpoints.down('800')]:{
      width: '100%',
      
    },
    
      [theme.breakpoints.up('sm')]:{
        
        maxHeight: '35vw',
      }
  },
 
  profileGrid:{
    
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: "93vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]:{
      marginBottom: 0,
    },

  
  },
  card: {
    width: '100%',
    maxWidth: '1000px',
    display: 'flex',
    borderRadius: 0,
    boxShadow: 0,
    flexDirection: 'row-reverse',
    //minHeight: '80vh',
    [theme.breakpoints.down('800')]:{
     flexDirection: 'column',
     marginTop: '45px',
    }
  },
  cardContent: {
    //textAlign: 'center',
    width: '50%',
    boxSizing:  'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  
    [theme.breakpoints.down(800)]:{
      width: '100%'
    }
  },
  bodyContent: {
    paddingTop: '30px',
    wordWrap: 'break-word',
    fontSize: "1.1rem"
  },
  bodyProjectButton: {
    color: lime[900],
    borderColor: lime[900],
    marginLeft: '5px',
    marginRight: '5px'
  },
  showSmallScreen: {
    visibility: "hidden",
    display: 'none',
    [theme.breakpoints.down(800)]:{
      visibility:"visible",
      //width: 0,
      //height: 0,
      display: 'inline',
    }
  },
  hideSmallScreen:{
    display:'inline',

    visibility: "visible",
    [theme.breakpoints.down(800)]:{
      //visibility: "hidden",
      //width: 0,
      //height: 0,
      display: 'none',
    }
  },
  iconButtonsLinkedInAndGit:{
    color: lime[900]
  }

}));

function Home() {
  const classes = useStyles();

  return (
    <Grid container className={classes.foundationItemGrid}  spacing={0}>
      <Grid item  sm={2} >
      <Hidden xsDown><ThreejsHome /></Hidden>
        </Grid>
        <Grid item xs={10} sm={8}  className={classes.profileGrid} >
         <Card className={classes.card} variant="elevation">
           <CardMedia image={jeshurun} alt="profile" className={classes.media}></CardMedia>
           <CardContent className={classes.cardContent}>
             <Typography variant="h1">Hello!</Typography>
             <Typography className={classes.hideSmallScreen} variant="h5">I am Ezeobi Jeshurun</Typography>
             <Typography className={classes.showSmallScreen} variant="h6">My name is Ezeobi Jeshurun</Typography>
             <Typography className={classes.bodyContent} variant="body1">I am a web and mobile developer, with over 5 years experience in software development. I am passionate about React Eco-system, Artificial Intelligence and Machine learning
             .I can help your team build applications from conception to production. 
              I studied Computer Engineering at Federal University of Technology Minna.
             
             </Typography>
             <Typography className={classes.bodyContent} variant="body1">
             I have worked with Happierleads as lead frontend developer, and GameAnalytics under GG Software United Kingdom.

             </Typography>
             <br/>
             <div>
             <a  href="https://www.linkedin.com/in/jeshurun-ezeobi-a88992b9" target="_blank" rel="noreferrer  noopener">
             <IconButton>
               <LinkedInIcon className={classes.iconButtonsLinkedInAndGit}/>
             </IconButton>
             </a>
             <Link to={'/project'}>
            <Button variant="outlined" className={classes.bodyProjectButton}  > projects </Button>
            </Link>
            <a  href="https://github.com/EzeobiJeshurun/" target="_blank" rel="noreferrer  noopener">
             <IconButton>
               <GitHubIcon className={classes.iconButtonsLinkedInAndGit}/>
             </IconButton>
             </a>
             </div>
           </CardContent>

           

         </Card>
         
      </Grid >
      <Grid item sm={2} >
      <Hidden xsDown><ThreejsHome /></Hidden>
      </Grid>
        
    </Grid>
  );
}


export default Home;

