import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Services from './components/Services';
import NewProjects from './components/NewProjects';


import './App.css';
import ButtonAppBar from './components/ButtonAppBar';
function App() {
  return (
    <React.Fragment>
      <Router>
     <ButtonAppBar/>
     <Switch>
     <Route exact path="/" component={Home}/>
     <Route exact path="/services" component={Services}/>
     <Route exact path="/contact" component={Contact}/>
     <Route exact path="/project" component={NewProjects}/>
     </Switch>
     </Router>
     </React.Fragment>
    
  );
}

export default App;
