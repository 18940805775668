import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../data";
import ImgMediaCard from "./ImageCard/ImageCard";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "15px",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  containerGrid: {
    maxWidth: "1000px",
    padding: "15px",
  },
  titleText: {
    width: "100%",
    padding: "15px",
    fontSize: "1.7rem",
    textAlign: "center",
    color: "#827717",
  },
}));

const NewProjects = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span className={classes.titleText}>Personal Projects</span>
      <Grid container classes={{ root: classes.containerGrid }}>
        {images.map((item) => (
          <Grid item xs={12} sm={6} key={item.img}>
            <ImgMediaCard
              maxWidth={550}
              height={"auto"}
              imgUrl={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              description={item.description}
              title={item.title}
              link={item.link}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default NewProjects;
