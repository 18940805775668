import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Link} from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
const useStyles = makeStyles(theme => ({
  
  foundationItemGrid: {
    
    paddingTop: '3rem',
    diplay: 'flex',
    //flexDirection: 'row-reverse',
    marginTop: '4rem',
   // background: '#ffffde',
    background: '#ffffce',
    backgroundColor: '#5de6de',
    backgroundImage: 'linear-gradient(315deg, #5de6de 0%, #b58ecc 74%)',
    marginLeft: 'auto',
    marginRight: 'auto',
    //height: '100vh',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]:{
      flexDirection: 'row',
      paddingTop: 50,
      marginTop: 0,
    }
  
  },
  media:{
    
    objectFit: 'cover',
    width: '50%',
    
    
    minHeight: '600px',
    
    [theme.breakpoints.down('800')]:{
      width: '100%',
      
    },
    
      [theme.breakpoints.up('sm')]:{
        
        maxHeight: '30vw',
      }
  },
 
  profileGrid:{
    
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '92vh',
    [theme.breakpoints.down('xs')]:{
      marginBottom: 0,
    },

  
  },
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 0,
    boxShadow: 0,
    textAlign: 'center',
    backgroundColor: '#3e424b',
    
    [theme.breakpoints.down('800')]:{
     flexDirection: 'column',
     marginTop: '45px',
    }
  },
  cardContent: {
    //textAlign: 'center',
    width: '100%',
    boxSizing:  'border-box',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    [theme.breakpoints.down(800)]:{
      width: '100%'
    }
  },
  bodyContent: {
    paddingTop: '30px',
    wordWrap: 'break-word',
    
    
  },
  bodyProjectButton: {
    color:  '#eee8aa',
    borderColor:  '#eee8aa',
    marginLeft: '5px',
    marginRight: '5px'
  },
  showSmallScreen: {
    visibility: "hidden",
    display: 'none',
    [theme.breakpoints.down(800)]:{
      visibility:"visible",
      //width: 0,
      //height: 0,
      display: 'inline',
    }
  },
  hideSmallScreen:{
    display:'inline',

    visibility: "visible",
    [theme.breakpoints.down(800)]:{
      //visibility: "hidden",
      //width: 0,
      //height: 0,
      display: 'none',
    }
  },
  iconButtonsLinkedInAndGit:{
    color: '#eee8aa'
  },
  explainationText:{
    textAlign: 'center',
    color: 'white',
  },
  paintLime: {
      color: '#eee8aa',
      textAlign: 'center'
  }

}));

function Contact() {
  const classes = useStyles();

  return (
    <Grid container className={classes.foundationItemGrid}  spacing={0}>
      <Grid item  sm={4}>
        
        </Grid>
        <Grid item xs={10} sm={4} className={classes.profileGrid} >
         <Card className={classes.card} variant="elevation">
         
          
           <CardContent className={classes.cardContent}>
           <Typography className={classes.paintLime} variant="body1">Open to relocation or Remote role</Typography>
           <br/>
             <Typography className={classes.explainationText} variant="body1"><span className={classes.paintLime}>Based :</span> Anambra State, Nigeria.</Typography>
             <br/>
             <Typography className={classes.paintLime} variant="body2">Email:</Typography>
             
             <Typography className={classes.explainationText} variant="body1">dev@jeshurunezeobi.com</Typography>
             <Typography className={classes.explainationText} variant="body1">jeshurunnonso@gmail.com</Typography>
               <br/>  
            
             
             <div>
             <a  href="https://www.linkedin.com/in/jeshurun-ezeobi-a88992b9" target="_blank" rel="noreferrer  noopener">
             <IconButton>
               <LinkedInIcon className={classes.iconButtonsLinkedInAndGit}/>
             </IconButton>
             </a>
             <Link to={'/project'}>
            <Button variant="outlined" className={classes.bodyProjectButton}  > projects </Button>
            </Link>
            <a  href="https://github.com/EzeobiJeshurun/" target="_blank" rel="noreferrer  noopener">
             <IconButton>
               <GitHubIcon className={classes.iconButtonsLinkedInAndGit}/>
             </IconButton>
             </a>
             </div>
           

           </CardContent>

         </Card>
         
      </Grid >
      <Grid item sm={4}>

      </Grid>
        
    </Grid>
  );
}


export default Contact;


