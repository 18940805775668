import * as React from 'react';
import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(()=>({
link: {
    margin: "15px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "&:hover": {
        //borderColor: "linear-gradient(to right, #33ccff 0%, #ffffff 66%)",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        transition: "border-color 0.8s ease",
    }
}
}));

const ImgMediaCard=({imgUrl, height, maxWidth, title, description, link })=> {
    const classes = useStyles();
  return (
    <a href={link} target="_blank" rel="noreferrer  noopener" >
    <div className={classes.link}>   
    <Card sx={{ maxWidth: maxWidth }} >
      <CardMedia
        component="img"
        alt="project sample image"
        height={height}
        image={imgUrl}
        loading="lazy"
        style={{minHeight: "200px"}}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      {/*<CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>*/}
    </Card>
    </div> 
    </a>
  );
}

export default ImgMediaCard;