import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import lime from '@material-ui/core/colors/lime';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import  IconButton  from '@material-ui/core/IconButton';


const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    height: '100vh'
  },
  buttonModal:{
    color: lime[900],
    background: 'inherit',
    border: 'none',
    outline: 'none',
    width: '100%',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0px'
  },
  closeButton: {
    color: lime[900],
    background: 'inherit',
   
  },
  itemButtons: {
    display: 'flex',
    flexDirection: 'row',
    color: lime[900],
    background: 'inherit',
    border: 'none',
    outline: 'none',
    width: '100%',
    height: '40px',
    alignItems: 'center',
    alignContent: 'start',
    borderRadius: '0px',
    margin: '0px, 0px',
    verticalAlign: 'bottom',
    "&:hover": {
      background: lime[900],
      color: 'white'
    },
  },
  pushAllButtonsUp: {
    marginBottom: 'auto',
    marginTop: 'auto',
    background: 'transparent',
    height: '79%'
  },
  paper: {
    width: '100%'
  },
  containerGridLayout: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    background: 'white',
  },
  modalNavigation:{
    zIndex: theme.zIndex.drawer + 2,
    background: 'white',
    width: '60vw',
    height: '100vh',
    display: 'flex',
    paddingTop: '20px',
    displayDirection: 'row',
    justifyContent: 'start'

  }
}));

export default function SimpleBackdrop() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Grid container>
      <Button  className={classes.buttonModal} onClick={handleToggle}>
        <MenuIcon />
      </Button>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        
      </Backdrop>
      <Backdrop className={classes.modalNavigation} open={open}>
      <Grid container item className={classes.containerGridLayout} >
      <Grid item className={classes.paper}>
          <List  className={classes.paper}><ListItem color="inherit" onClick={()=>{handleClose()}} 
           button><ListItemSecondaryAction><IconButton className={classes.closeButton}  onClick={()=>{handleClose()}}><CloseIcon/></IconButton></ListItemSecondaryAction></ListItem></List>
        </Grid>
      <Grid item className={classes.paper}>
          
      <List component="nav" className={classes.root} aria-label="mailbox folders">
      <Divider /> 
      <Link to={'/'}  > 
      <ListItem button onClick={handleClose}>
        <ListItemText primary="home" />
      </ListItem>
      </Link>
      <Divider />
      <Link to={'/contact'}>
      <ListItem button onClick={handleClose}>
        <ListItemText primary="Contact" />
      </ListItem>
      </Link>
      <Divider />
      <Link to={'/project'}>
      <ListItem button onClick={handleClose}>
        <ListItemText primary="Project" />
      </ListItem>
      </Link>
     <Divider />
     <Link to={'/services'}>
      <ListItem button onClick={handleClose}>
        <ListItemText primary="Services" />
      </ListItem>
      </Link>
      <Divider />
    </List>
        </Grid>
        
     
          <div className={classes.pushAllButtonsUp}></div>
      </Grid>
         
      </Backdrop>
    </Grid>
  );
}