import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import lime from '@material-ui/core/colors/lime';
import Hidden from '@material-ui/core/Hidden';
import SimpleBacKdrop from './SimpleBackdrop';
import HomeIcon from '@material-ui/icons/Home';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    boxSizing: 'border-box',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: "auto !important",
    fontSize: "1.7rem"
  },
  Appbarcolor: {
    boxSizing: 'border-box',
    background: 'white',
    color: lime[900],
    flexGrow: 1,
  },
  buttonSpace: {
    marginRight: theme.spacing(2)
  }
}));




export default function ButtonAppBar() {
  const classes = useStyles();

  const [{home, contact, services, projects } , setButton ] = useState({home: false, contact:false, services: false , projects: false } );

  return (
    <div className={classes.root}>
      <AppBar className={classes.Appbarcolor}  position="fixed">
        <Toolbar>
        <Hidden smUp>
          <div  edge="start"  className={classes.menuButton} color="inherit" aria-label="menu" >
            {/**place the menuIcon here */}
            <SimpleBacKdrop color="inherit"/>
          </div>
          </Hidden>
          <span className={classes.title}>
            Portfolio
          </span>
          <Hidden xsDown>
          <Link to={"/"}>
          <Button color={ home? "default":"inherit"} onClick ={()=>{
            if(!home){
              setButton( {home: true, contact:false, services: false , projects: false });
            }
          }} >home</Button>
          </Link>
          <Link to={'/contact'}>
          <Button color={ contact? "default":"inherit"} onClick ={()=>{
            if(!contact){
              setButton({home: false, contact:true, services: false , projects: false });
            }
          }}>contact</Button>
          </Link>
          <Link to={'/services'}>
          <Button color={ services? "default":"inherit"} onClick={()=>{  
            if(!services){
              setButton({home: false, contact:false, services: true , projects: false })
            }
          }}>services</Button>
          </Link>
          <Link to={'/project'}>
          <Button color={ projects? "default":"inherit"} onClick={()=>{
            if(!projects){
              setButton({home: false, contact:false, services: false , projects: true });
            }  
          }} className={classes.buttonSpace}>projects</Button>
          </Link>
          </Hidden>
          <Hidden smUp>
          <Link to={'/'}><Button color="inherit" className={classes.buttonSpace}><HomeIcon/></Button></Link>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}