import React, { useRef, useState, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars} from '@react-three/drei';

const Box=(props) =>{
    
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
      //return mesh.current.rotation.x += 0.01
  })
    
    return (
      <mesh
        {...props}
        ref={mesh}
        scale={active ? 1.5 : 1}
        onClick={(event) => setActive(!active)}
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}>
        <sphereBufferGeometry args={[0.25, 30, 30]} />
        <meshLambertMaterial color={hovered ? 'hotpink' : '#0d304d'} />
      </mesh>
    )
  }


  const ThreejsHome =()=>{
    
      return (
        <Canvas>
        <OrbitControls/>
        <Stars/>    
        <Suspense fallback={null}>   
        <ambientLight />
        <spotLight position={[10, 50, 10]} angle={0.3}/>
        <Box position={[-1.2, 0, 0]} />
        <Box position={[1, 3, -1]} />
        <Box position={[-3, 1, 4]} />
        <Box position={[1.2, 1, 0]} />
        <Box position={[-1.2, 5, 0]} />
        <Box position={[1.2, 7, 0]} />
        <Box position={[-1.2, 8, -2]} />
        <Box position={[1.2, -3, -3]} />
        </Suspense> 
      </Canvas>
      )
  }
  export default ThreejsHome;

  