import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Link} from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => ({
  
  foundationItemGrid: {
    
    paddingTop: '3rem',
    diplay: 'flex',
    //flexDirection: 'row-reverse',
    marginTop: '4rem',
   // background: '#ffffde',
    background: '#ffffce',
    backgroundColor: '#eec0c6',
    backgroundImage: 'linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%)',

    marginLeft: 'auto',
    marginRight: 'auto',
    //height: '100vh',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]:{
      flexDirection: 'row',
      paddingTop: 50,
      marginTop: 0,
    }
  
  },
  media:{
    
    objectFit: 'cover',
    width: '50%',
    
    
    minHeight: '600px',
    
    [theme.breakpoints.down('800')]:{
      width: '100%',
      
    },
    
      [theme.breakpoints.up('sm')]:{
        
        maxHeight: '30vw',
      }
  },
 
  profileGrid:{
    
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '92vh',
    [theme.breakpoints.down('xs')]:{
      marginBottom: 0,
    },

  
  },
  card: {
    width: '100%',
    display: 'flex',
    borderRadius: 0,
    boxShadow: 0,
    flexDirection: 'column',
    backgroundColor: '#3e424b',
    alignItems: "center",
    minHeight: '80vh',
    [theme.breakpoints.down('800')]:{
     flexDirection: 'column',
     marginTop: '45px',
    }
  },
  cardContent: {
    //textAlign: 'center',
    width: '100%',
    boxSizing:  'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(800)]:{
      width: '100%'
    }
  },
  bodyContent: {
    paddingTop: '30px',
    wordWrap: 'break-word',
    
  },
  bodyProjectButton: {
    color:  '#eee8aa',
    borderColor:  '#eee8aa',
    marginLeft: '5px',
    marginRight: '5px'
  },
  showSmallScreen: {
    visibility: "hidden",
    display: 'none',
    [theme.breakpoints.down(800)]:{
      visibility:"visible",
      //width: 0,
      //height: 0,
      display: 'inline',
    }
  },
  hideSmallScreen:{
    display:'inline',

    visibility: "visible",
    [theme.breakpoints.down(800)]:{
      //visibility: "hidden",
      //width: 0,
      //height: 0,
      display: 'none',
    }
  },
  iconButtonsLinkedInAndGit:{
    color: '#eee8aa'
  },
  explainationText:{
    textAlign: 'start',
    color: 'white',
  },
  paintLime: {
      color: '#eee8aa'
  }

}));

function Services() {
  const classes = useStyles();

  return (
    <Grid container className={classes.foundationItemGrid}  spacing={0}>
      <Grid item  sm={3} md={4}>
        
        </Grid>
        <Grid item xs={10} sm={6} md={4} className={classes.profileGrid} >
         <Card className={classes.card} variant="elevation">
          <div>
         <CardContent className={classes.cardContent}>
         <Typography className={classes.paintLime} variant="h5">Web Technologies</Typography>
         <ul className={classes.explainationText}>
             <li>JavaScript</li>
             <li>TypeScript</li>
             <li>Jest</li>
             <li>React Testing Library</li>
             <li>React</li>
             <li>Redux</li>
             <li>MongoDb</li>
             <li>Firebase(admin,SDK)</li>
             <li>GraphQL</li>
             <li>Material-ui</li>
             <li>Bootstrap</li>
             <li>SQL</li>
             <li>CSS, SASS</li>
             <li>HTML5</li>
             <li>Nextjs</li>
             <li>Threejs</li>
             <li>Cypress</li>
             <li>Storybook</li>
         </ul>
          </CardContent>   
           <CardContent className={classes.cardContent}>
             <Typography className={classes.paintLime} variant="h5">Mobile Technologies</Typography>
             <ul className={classes.explainationText}>
                 <li>Flutter</li>
                 <li>Dart</li>
                 <li>MLKit</li>
                 <li>Firebase SDK</li>
                 <li>WebRTC and webSocket</li>
                 <li>OAuth2</li>
             </ul>
             
             
               <br/>  
             <Typography className={classes.paintLime} variant="h5">General Technologies</Typography>
             <ul className={classes.explainationText}>
                 <li>AWS Machine Learning Services</li>
                 <li>AWS Pinpoint</li>
                 <li>Google Cloud AutoML</li>
             </ul>
             <br/>
             

           </CardContent>
           </div>
           <div className={"w-100"}>
             <a  href="https://www.linkedin.com/in/jeshurun-ezeobi-a88992b9" target="_blank" rel="noreferrer  noopener">
             <IconButton>
               <LinkedInIcon className={classes.iconButtonsLinkedInAndGit}/>
             </IconButton>
             </a>
             <Link to={'/project'}>
            <Button variant="outlined" className={classes.bodyProjectButton}  > projects </Button>
            </Link>
            <a  href="https://github.com/EzeobiJeshurun/" target="_blank" rel="noreferrer  noopener">
             <IconButton>
               <GitHubIcon className={classes.iconButtonsLinkedInAndGit}/>
             </IconButton>
             </a>
             </div>
         </Card>
         
      </Grid >
      <Grid item sm={3} md={4}>

      </Grid>
        
    </Grid>
  );
}


export default Services;


