import chat3 from "./assets/chat3.png";
import shop1 from "./assets/shop1.png";
import sibel2 from "./assets/sibel2.png";
import reactCert from "./assets/hackerRank_React.png";
import javascriptCert from "./assets/hackerRank_javascript.png";
import mlCert from "./assets/machine_learning_foundation.png";
import gitCert from "./assets/git_githuh.jpg";
import pythonCert from "./assets/hackerRank_python.png";
import JestAndTestingLibCert from "./assets/Jest_React_Testing_Library.png";
import TypescriptProfessionals from "./assets/typescript_professionals.png";

export const images =[
    {
        img: chat3,
        title: "Chat application",
        description: "A react chat built for communication, users can create, comment and like posts. Each user has a dashboard.",
        link: "https://chat.jeshurunezeobi.com"
    },
    {
        img: shop1,
        title: "Online Shop",
        description: "A react store that helps users buy things online with great ease.",
        link: "https://www.shop.jeshurunezeobi.com",
    },
    {
        img: sibel2,
        title: "simple chat bot",
        description: "websocket project, collects certain details from users",
        link: "https://www.sibel.jeshurunezeobi.com"
    },
    {
        img: gitCert,
        title: "Git & GitHub - The Complete Git & GitHub Course",
        link: "https://www.udemy.com/certificate/UC-171c036b-f70e-4bf4-96d5-49d5aa629c4f/",
        description: "This course will teach about one of the most popular and widely used version control systems today."
     },
    {
        img: reactCert,
        title: "React (Basic)",
        link: "https://www.hackerrank.com/certificates/bae08a3d21e6",
        description: "React (Basic) It covers topics like Basic Routing, Rendering Elements,State Management (Internal Component State), Handling Events, ES6 and JavaScript and Form Validation."
    },
    {
        img: JestAndTestingLibCert,
        title: "Testing React with Jest and Testing Library",
        link: "https://www.udemy.com/certificate/UC-da4e89ff-6ddc-4106-856e-a00d94f21a38/",
        description: "Learn best practices for testing your apps with Jest and React Testing Library!"
    },
    {
      img: TypescriptProfessionals,
      title: "TypeScript for Professionals",
      link: "https://www.udemy.com/certificate/UC-60f4e141-bff4-4584-9f6d-e4ddc198f15a/",
      description: "Learn all about the TypeScript type system - taught by a Microsoft MVP"
    },
    {
        img: javascriptCert,
        link: "https://www.hackerrank.com/certificates/8e1616da5cf5",
        title: "JavaScript (Basic)",
        description: "It covers topics like, Functions, Currying, Hoisting, Scope, Inheritance, Events and Error Handling."
    },
    { 
        img: mlCert,
        link: "https://confirm.udacity.com/TK75AC4C",
        title: "AWS Machine Learning Foundations Course",
        description: "Learn the basics of machine learning and practice with AWS"
    },
    
    {
        img: pythonCert,
        title: "Python (Basic)",
        description: "It covers topics like Scalar Types, Operators and Control Flow, Strings, Collections and Iteration, Modularity, Objects and Types and Classes",
        link: "https://www.hackerrank.com/certificates/a1a48033e22a",
    },
    
]